import RRule, { Options } from 'rrule';
import { sentenceCase } from 'sentence-case';
import { PetRecord } from '../components/Pets/types';
import { Slots } from '../hooks/useCheckBranchAvailability';
import { Slot } from '../hooks/useMemoizedFullDates';
import { Product } from '../views/Store/types';

export const MONTH_NAMES = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const FULL_DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'] as const;

export const twoLetterDays = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'] as const;
export const FULL_WEEK_DAYS_MAP = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'] as const;

export const WEEK_DAYS_MAP = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'] as const;
export const WEEK_DAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'] as const;
export const SUN_THREE_DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'] as const;

export const SUN_THREE_LETTERS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'] as const;

export const THREE_LETTER_WEEK_DAYS = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'] as const;

export const DATE_SLOTS_MAX = 180 as const;

export const MONTH_FULL_NAMES = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
export const getUTCTime = (slot: Slots['availableSlots'][0][0]) => {
  const date = new Date(slot?.timestamp);
  let hh = date.getUTCHours();
  let mm = date.getUTCMinutes();

  if (hh < 10) {
    hh = '0' + hh;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }

  const ampm = hh >= 12 ? 'PM' : 'AM';
  hh = hh % 12;
  hh = hh ? hh : 12;

  return `${hh}:${mm} ${ampm}`;
};

export const yearQuarters = [
  {
    from: new Date(new Date().getFullYear(), 0, 1),
    to: new Date(new Date().getFullYear(), 2, 31)
  },
  {
    from: new Date(new Date().getFullYear(), 3, 1),
    to: new Date(new Date().getFullYear(), 5, 30)
  },
  {
    from: new Date(new Date().getFullYear(), 6, 1),
    to: new Date(new Date().getFullYear(), 8, 30)
  },
  {
    from: new Date(new Date().getFullYear(), 9, 1),
    to: new Date(new Date().getFullYear(), 11, 31)
  }
];

export const randomDate = () => {
  return new Date(Date.now() - Math.random() * 1000000000);
};

export const findMonthName = (mon: number) => {
  return MONTH_NAMES[mon];
};

export const findFMonthFullName = (mon: number) => {
  return MONTH_FULL_NAMES[mon];
};

export function getFormattedDate({ date = new Date(), prefomattedDate = '', hideYear = false, hideTime = false }) {
  const day = date.getUTCDate();
  const month = MONTH_NAMES[date.getUTCMonth()];
  const year = date.getUTCFullYear();
  const hours = date.getUTCHours();
  let minutes: number | string = date.getUTCMinutes();
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  if (prefomattedDate) {
    return `${prefomattedDate} at ${hours}:${minutes}`;
  }
  if (hideYear) {
    return `${day} ${month} at ${hours}:${minutes}`;
  }
  if (hideTime) {
    return `${day} ${month} ${year}`;
  }
  return `${day} ${month} ${year} at ${hours}:${minutes}`;
}

export function timeAgo(dateParam: Date) {
  if (!dateParam) {
    return null;
  }
  const date = typeof dateParam === 'object' ? dateParam : new Date(dateParam);
  const today = new Date();
  const seconds = Math.round((today - date) / 1000);
  const minutes = Math.round(seconds / 60);
  const isTodayVal = isToday(date);
  const isYesterdayVal = isYesterday(date);
  const isThisYear = today.getFullYear() === date.getFullYear();

  if (seconds < 5) {
    return 'now';
  } else if (seconds < 60) {
    return `seconds ago`;
  } else if (seconds < 90) {
    return 'about a minute ago';
  } else if (minutes < 60) {
    return `${minutes} minutes ago`;
  } else if (isTodayVal) {
    return getFormattedDate({ date, prefomattedDate: 'Today' });
  } else if (isYesterdayVal) {
    return getFormattedDate({ date, prefomattedDate: 'Yesterday' });
  } else if (isThisYear) {
    return getFormattedDate({ date, hideYear: true });
  }

  return getFormattedDate({ date });
}

export function toReadableDate(date: Date | string | number, options: { noTime?: boolean; withDay?: boolean; isLocale?: boolean } = { noTime: false, withDay: false, isLocale: false }) {
  const { noTime, withDay } = options;
  if (!new Date(date)?.getTime()) {
    return '';
  }
  if (noTime) {
    return new Intl.DateTimeFormat('en-GB', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: withDay ? 'short' : undefined,
      ...(!options.isLocale && { timeZone: 'UTC' })
    }).format(new Date(date));
  }
  return new Intl.DateTimeFormat('en-GB', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    weekday: withDay ? 'short' : undefined,
    ...(!options.isLocale && { timeZone: 'UTC' })
  }).format(new Date(date));
}
export function toDateInput(date: Date | string) {
  const d = new Date(date || Date.now());
  return new Intl.DateTimeFormat('en-GB', {
    dateStyle: 'short',
    timeZone: 'UTC'
  }).format(new Date(d));
}

export function toMonth(date: Date) {
  return Intl.DateTimeFormat('en-GB', { month: 'long', timeZone: 'UTC' }).format(new Date(date));
}

export function toMonthAndDay(date: Date) {
  return Intl.DateTimeFormat('en-GB', { month: 'short', day: 'numeric' }).format(new Date(date));
}

export function toWeekDayWithoutYear(date: Date) {
  return Intl.DateTimeFormat('en-GB', { weekday: 'long', month: 'short', day: 'numeric' }).format(new Date(date));
}

export function toDateWithSlashes(dateInput: Date | null) {
  if (!dateInput) {
    return '';
  }
  const date = new Date(dateInput);
  return new Intl.DateTimeFormat('en-GB', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone: 'UTC'
  }).format(date);
}

export const calculateAgeFromBirthdate = (birthdate: string, startDate = new Date(), representation = 'long', displayMonth = true) => {
  if (!birthdate) {
    return '';
  }
  const date = new Date(new Date(startDate).getTime() - new Date(birthdate).getTime());
  const years = Math.abs(date.getFullYear() - 1970);
  const months = date.getMonth();
  let repY = 'Year',
    repM = 'Month',
    repD = 'Day';
  if (representation === 'short') {
    repY = 'Y';
    repM = 'M';
    repD = 'D';
  }
  // if (!displayMonth && !!years) {
  //   return `${years} ${repY}${years > 1 ? 's' : ''}`;
  // }

  if (!!years && !!months) {
    return `${years} ${repY}${years > 1 ? 's' : ''}, ${months} ${repM}${months > 1 ? 's' : ''}`;
  }
  if (!years && !!months) {
    return `${months} ${repM}${months > 1 ? 's' : ''}`;
  }
  if (!!years && !months) {
    return `${years} ${repY}${years > 1 ? 's' : ''}`;
  }
  const days = date.getUTCDate();
  if (!years && !months && !!days) {
    return `${days} ${repD}${days > 1 ? 's' : ''}`;
  }
};

export const rruleToReadableTime = (repTime: Partial<Options>) => {
  const hour = repTime.byhour[0] || repTime.byhour;
  const minute = repTime.byminute[0] || repTime.byminute;
  return `${hour < 10 ? '0' + hour : hour}:${minute < 10 ? '0' + minute : minute}`;
};

export function getUTCHourOffset(date = new Date()) {
  return new Date(date).getTimezoneOffset() / -60;
}

export const isYesterday = (date: Date) => {
  const yesterday = getStartOfToday();
  yesterday.setUTCDate(yesterday.getUTCDate() - 1);
  return date.toDateString() === yesterday.toDateString();
};

export const isToday = (date: Date) => {
  const today = getStartOfToday();
  return date.toDateString() === today.toDateString();
};

export const isTomorrow = (date: Date) => {
  const tomorrow = getStartOfToday();
  tomorrow.setUTCDate(tomorrow.getUTCDate() + 1);
  return date.toDateString() === tomorrow.toDateString();
};

export const formatDateDay = (
  date: string | Date,
  options: { noWeekDay?: boolean; alwaysIncludeMonth?: boolean; alwaysDate?: boolean } = { noWeekDay: false, alwaysIncludeMonth: false, alwaysDate: false }
) => {
  const { noWeekDay, alwaysIncludeMonth, alwaysDate } = options;
  if (!new Date(date)?.getTime()) {
    return '';
  }
  if (alwaysDate) {
    return toMonthAndDay(new Date(date));
  }

  if (isYesterday(new Date(date))) {
    if (alwaysIncludeMonth) {
      return 'Yesterday, ' + toMonthAndDay(new Date(date));
    }
    return 'Yesterday';
  }

  if (isToday(new Date(date))) {
    if (alwaysIncludeMonth) {
      return 'Today, ' + toMonthAndDay(new Date(date));
    }
    return 'Today';
  }
  if (isTomorrow(new Date(date))) {
    if (alwaysIncludeMonth) {
      return 'Tomorrow, ' + toMonthAndDay(new Date(date));
    }
    return 'Tomorrow';
  }

  if (noWeekDay) {
    return toMonthAndDay(new Date(date));
  }

  return toWeekDayWithoutYear(new Date(date));
};

export const formatTime = (
  date: string,
  options: {
    twentyFourHour?: boolean;
  } = {
    twentyFourHour: false
  }
) => {
  const { twentyFourHour } = options;
  if (!new Date(date)?.getTime()) {
    return '';
  }
  return new Intl.DateTimeFormat('en-GB', {
    hour: twentyFourHour ? 'numeric' : '2-digit',
    minute: '2-digit',
    timeZone: 'UTC'
  }).format(new Date(date));
};

export const getHourByIndex = (index: number) => {
  const start = new Date();
  start.setUTCHours(0, 0, 0, 0);
  start.setTime(start.getTime() + index * 60 * 60 * 1000);
  return new Intl.DateTimeFormat('en-GB', {
    timeStyle: 'short',
    hour12: true
  })
    .format(new Date(start))
    .replace('00:', '12:');
};

export const getHourByIndexTitle = (index: number) => {
  const start = new Date();
  start.setUTCDate(start.getUTCDate() + 1); // TODO: remove this when we have a better solution
  start.setHours(0, 0, 0, 0);
  start.setTime(start.getTime() + index * 60 * 60 * 1000);
  return new Intl.DateTimeFormat('en-GB', {
    timeStyle: 'short',
    hour12: true
  })
    .format(new Date(start))
    .replace('00:', '12:')
    .replace('am', 'AM')
    .replace('pm', 'PM')
    .replace(':00', '');
};

export const toTimeInput = (index: number | string) => {
  const start = new Date();
  start.setUTCHours(0, 0, 0, 0);
  start.setTime(start.getTime() + index * 60 * 60 * 1000);
  return new Intl.DateTimeFormat('en-GB', {
    timeStyle: 'short',
    timeZone: 'UTC'
  }).format(new Date(start));
};

export const convertTo24Hour = (time: string) => {
  const [hour, minute] = time.split(':');
  const [minuteNumber, ampm] = minute.split(' ');
  const hourNumber = parseInt(hour, 10);
  if (hourNumber === 12 && ampm === 'AM') {
    return `00:${minuteNumber}`;
  }

  if (ampm === 'AM') {
    return `${hourNumber < 10 ? '0' + hourNumber : hourNumber}:${minuteNumber}`;
  }

  if (hourNumber === 12 && ampm === 'PM') {
    return `${hourNumber}:${minuteNumber}`;
  }

  return `${hourNumber + 12}:${minuteNumber}`;
};

export function formatAppointmentPeriod(date: Date, duration: number) {
  const from = new Intl.DateTimeFormat('en-GB', {
    timeStyle: 'short',
    timeZone: 'UTC'
  }).format(new Date(date));
  const to = new Intl.DateTimeFormat('en-GB', {
    timeStyle: 'short',
    timeZone: 'UTC'
  }).format(new Date(date.getTime() + duration * 60000));

  return `${from} - ${to}`.replace('00:', '12:');
}

export function getUTCDaysDiffBetweenDates(date1: Date | string, date2: Date | string) {
  const date1Copy = new Date(date1);
  const date2Copy = new Date(date2);
  const diffTime = Math.abs(date2Copy.getTime() - date1Copy.getTime());
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24)) + 1;
  return diffDays;
}

export function formatMultiDayAppointmentPeriod(date: Date, timestamp_until: Date) {
  const from = new Intl.DateTimeFormat('en-GB', {
    month: 'short',
    day: 'numeric',
    timeZone: 'UTC'
  }).format(new Date(date));
  const to = new Intl.DateTimeFormat('en-GB', {
    month: 'short',
    day: 'numeric',
    timeZone: 'UTC'
  }).format(new Date(timestamp_until));

  return `${from} - ${to}`;
}

export function getMonday(d: Date): Date {
  const date = new Date(d);
  const day = date.getDay();
  const diff = date.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
  return new Date(date.setDate(diff));
}

export function formatWeekDayOfMonth(date: Date) {
  return Intl.DateTimeFormat('en-GB', { weekday: 'short', month: 'long', day: 'numeric', timeZone: 'UTC' }).format(new Date(date));
}

export function formatFullWeekDayOfMonth(date: Date) {
  return Intl.DateTimeFormat('en-GB', { weekday: 'long', month: 'long', day: 'numeric', timeZone: 'UTC' }).format(new Date(date));
}

export const toThreeLetterWeekDay = (date: Date) => {
  return Intl.DateTimeFormat('en-GB', { weekday: 'short' }).format(new Date(date));
};

export function formatDateToDay(date: Date) {
  return Intl.DateTimeFormat('en-GB', { day: 'numeric', timeZone: 'UTC' }).format(date);
}
export function formatDateToDayAndMonth(date: Date) {
  return Intl.DateTimeFormat('en-GB', { month: 'long', day: 'numeric', timeZone: 'UTC' }).format(date);
}
export function formatDateToDayMonthAndYear(date: Date) {
  return Intl.DateTimeFormat('en-GB', { month: 'long', day: 'numeric', year: 'numeric', timeZone: 'UTC' }).format(new Date(date));
}

export function formatDateToMonth(date: Date) {
  return Intl.DateTimeFormat('en-GB', { month: 'long', timeZone: 'UTC' }).format(date);
}

export function formatDateToYear(date: Date) {
  return Intl.DateTimeFormat('en-GB', { year: 'numeric', timeZone: 'UTC' }).format(date);
}

export function formatPeriod({ startDate, period }: { startDate: Date; period: number }): string {
  const endOfWeekDay = new Date(startDate);
  endOfWeekDay.setUTCDate(startDate.getUTCDate() + period - 1);

  if (startDate.getFullYear() !== endOfWeekDay.getFullYear()) {
    return `${formatDateToDayMonthAndYear(startDate)} - ${formatDateToDayMonthAndYear(endOfWeekDay)}`;
  }
  if (startDate.getMonth() !== endOfWeekDay.getMonth()) {
    return `${formatDateToDayAndMonth(startDate)} - ${formatDateToDayAndMonth(endOfWeekDay)}, ${startDate.getFullYear()}`;
  }

  return `${formatDateToDay(startDate)} - ${formatDateToDayAndMonth(endOfWeekDay)}, ${startDate.getFullYear()}`;
}

export function formatWeekDateToMonthAndYear(weekStartDay: Date): string {
  const endOfWeekDay = new Date(weekStartDay);
  endOfWeekDay.setUTCDate(weekStartDay.getUTCDate() + 6);

  return `${formatDateToMonth(weekStartDay)} ${weekStartDay.getFullYear()} `;
}

export const getNextDay = (date?: Date | string | null) => {
  if (!date || typeof date === 'string') {
    return null;
  }
  const nextDay = new Date(date);
  nextDay.setUTCDate(date?.getUTCDate() + 1);
  return nextDay;
};

export const getUTCDaysBetweenDates = (startDate: Date | string | null, endDate: Date | string | null) => {
  if (!startDate || !endDate) {
    return null;
  }
  const startDateCopy = new Date(startDate);
  const endDateCopy = new Date(endDate);
  const diffTime = Math.abs(endDateCopy.getTime() - startDateCopy.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

export const getBiggestAndSmallestDates = (dates: (Date | string)[]) => {
  if (!dates.length) {
    return {
      biggestDate: null,
      smallestDate: null
    };
  }
  const datesCopy = dates.map(date => new Date(date));
  const biggestDate = datesCopy.reduce((a, b) => (a > b ? a : b));
  const smallestDate = datesCopy.reduce((a, b) => (a < b ? a : b));
  return { biggestDate, smallestDate };
};

export function getAllWeekDays(monday: Date): Date[] {
  return Array(7)
    .fill('')
    .map((_, idx) => {
      const currentDay = new Date(monday);
      if (idx !== 0) {
        currentDay.setUTCDate(currentDay.getUTCDate() + idx);
      }
      return currentDay;
    });
}

export function getWeekDays(length: number, monday: Date): Date[] {
  return Array(length)
    .fill('')
    .map((_, idx) => {
      const currentDay = new Date(monday);
      if (idx !== 0) {
        currentDay.setDate(currentDay.getDate() + idx);
      }
      return currentDay;
    });
}

export const getPreviousDay = (date: Date) => {
  const previousDay = new Date(date);
  previousDay.setUTCDate(previousDay.getUTCDate() - 1);
  return previousDay;
};

export const getAllDaysInMonth = (month: number, year: number) => {
  const numberOfDays = new Date(year, month, 0).getUTCDate();
  return new Array(numberOfDays).fill('').map((_, idx) => idx + 1);
};

export const toExdateString = (d: Date) => {
  const date = new Date(d || Date.now());
  return `${date.getFullYear()}${date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}${
    date.getUTCDate() < 10 ? `0${date.getUTCDate()}` : date.getUTCDate()
  }T${getUTCHourOffset()}00000Z`;
};
export const fromExdateString = (exdate: string): Date => {
  return new Date(exdate.slice(0, 4) + '-' + exdate.slice(4, 6) + '-' + exdate.slice(6, 8));
};
export const fromRRuleExdateString = (repTime: Options, exdate: string) => {
  const hour = repTime.byhour[0] || repTime.byhour;
  const minute = repTime.byminute[0] || repTime.byminute;
  const date = new Date(fromExdateString(exdate));
  date.setUTCHours(hour, minute);
  return date;
};

export const isExdateInPast = (date: string) => {
  const today = new Date();
  today.setUTCHours(0, 0, 0, 0);

  return new Date(Number(date.slice(0, 4)), Number(date.slice(4, 6)) - 1, Number(date.slice(6, 8))) < today;
};

export const offsetUTC = (date: string | Date = new Date()) => new Date(date).getTimezoneOffset() * 60000;

export const formatOrdinals = (n: number) => {
  const pr = new Intl.PluralRules('en-US', { type: 'ordinal' });
  const suffixes = new Map([
    ['one', 'st'],
    ['two', 'nd'],
    ['few', 'rd'],
    ['other', 'th']
  ]);
  const rule = pr.select(n);
  const suffix = suffixes.get(rule);
  return `${n}${suffix}`;
};

export const getStartOfToday = () => {
  const startOfToday = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
  return startOfToday;
};

export const getStartOfTodayUTC = () => {
  const startOfToday = new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()));
  return startOfToday;
};

export const getStartOfDate = (date: Date | string | null) => {
  if (!date) {
    return null;
  }
  const startOfDay = new Date(date);
  startOfDay.setHours(0, 0, 0, 0);
  return startOfDay;
};

export const getEndOfYesterday = () => {
  const endOfYesterday = new Date();
  endOfYesterday.setDate(endOfYesterday.getDate() - 1);
  endOfYesterday.setHours(23, 59, 59, 999);
  return endOfYesterday;
};

export const getLastDayOfMonth = () => {
  const lastDayOfMonth = new Date();
  lastDayOfMonth.setMonth(lastDayOfMonth.getMonth() + 1);
  lastDayOfMonth.setDate(0);
  lastDayOfMonth.setHours(23, 59, 59, 999);
  return lastDayOfMonth;
};

export const getStartOfMonth = (date?: Date) => {
  const startOfMonth = new Date(date);
  startOfMonth.setDate(1);
  startOfMonth.setHours(0, 0, 0, 0);
  return startOfMonth;
};

export const getStartOfNMonth = (startDate: Date, numberOfMonths: number) => {
  const startOfMonth = new Date(startDate);
  startOfMonth.setMonth(startOfMonth.getMonth() + numberOfMonths);
  startOfMonth.setDate(1);
  startOfMonth.setHours(0, 0, 0, 0);
  return startOfMonth;
};

export const getPrevSecond = (date: Date | string) => {
  const prevSecond = new Date(date);
  prevSecond.setSeconds(prevSecond.getSeconds() - 1);
  return prevSecond;
};

export const getPrevMilliSecond = (date: Date | string) => {
  const prevSecond = new Date(date);
  prevSecond.setMilliseconds(prevSecond.getMilliseconds() - 1);
  return prevSecond;
};

export const getDateMonthName = (date: Date) => {
  return new Intl.DateTimeFormat('en-GB', { month: 'short', year: 'numeric', day: '2-digit' }).format(new Date(date));
};
export const getUTCDateMonthName = (date: Date) => {
  return new Intl.DateTimeFormat('en-GB', { month: 'short', year: 'numeric', day: '2-digit', timeZone: 'UTC' }).format(new Date(date));
};

export const getMonthName = (date: Date) => {
  return new Intl.DateTimeFormat('en-GB', { month: 'short' }).format(new Date(date));
};

export const getEndOfToday = () => {
  const endOfToday = new Date();
  endOfToday.setUTCHours(23, 59, 59, 999);
  return endOfToday;
};

export const getEndOfDate = (date: Date | string) => {
  const endOfDate = new Date(date);
  endOfDate.setUTCHours(23, 59, 59, 999);
  return endOfDate;
};

export const getStartOfToday8AM = () => {
  const time = getStartOfTodayUTC();
  time.setUTCHours(8);
  time.setUTCMinutes(0);
  return time;
};

export const toDay = (date: Date) => {
  const day = date.getDate();
  return day;
};

export const getShortISODate = (isoDate = new Date(), noReset = false) => {
  if (!noReset) {
    isoDate.setUTCHours(0);
    isoDate.setUTCMinutes(0);
    isoDate.setUTCSeconds(0);
    isoDate.setMilliseconds(0);
  }
  return isoDate.toISOString().replace(/-|:|\..*/g, '') + 'Z';
};

export const getSuppTime = (createdAt: Date, time: string, after = getStartOfToday()) => {
  const createdDate = new Date(createdAt);
  const str = `DTSTART:${getShortISODate(createdDate)}\n${time}`;
  const rruleTime = RRule.fromString(str || '');
  const timestamp = rruleTime.after(after, true);
  const mealTime = getStartOfToday8AM();
  timestamp.setUTCHours(mealTime.getUTCHours());
  timestamp.setUTCMinutes(mealTime.getUTCMinutes());
  return timestamp;
};

export const toLocaleDateString = (d: Date) => {
  const date = new Date(d || Date.now());
  return `${date.getUTCDate() < 10 ? `0${date.getUTCDate()}` : date.getUTCDate()}/${date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}/${date.getFullYear()}`;
};

export function getNextTimestampForSuppRecord(supp: any) {
  let nextTimestamp;
  if (!supp.time) {
    nextTimestamp = getStartOfToday();
  } else {
    nextTimestamp = getSuppTime(new Date(supp.Adherences?.[0]?.timestamp || supp.start_date), supp.time, new Date(supp.Adherences?.[0]?.timestamp || supp.start_date));
  }
  return nextTimestamp;
}

export const convertDateToDueIn = (nextDate: Date) => {
  const dt1 = new Date();
  const dt2 = new Date(nextDate);
  if (!nextDate || dt2 < getStartOfToday()) return nextDate;
  if (toLocaleDateString(new Date(nextDate)) === toLocaleDateString(new Date())) {
    return 'for Today';
  }
  if (toLocaleDateString(new Date(nextDate)) === toLocaleDateString(new Date(new Date().setUTCDate(new Date().getUTCDate() + 1)))) {
    return 'for Tomorrow';
  }
  return getDifferenceBetweenDates(dt1, dt2);
};

export const convertDateToOverdue = (nextDate: Date) => {
  if (new Date(nextDate) > getEndOfToday()) return nextDate;
  return getDifferenceBetweenDates(new Date(nextDate), new Date());
};

function getDifferenceBetweenDates(dt1: Date, dt2: Date) {
  const ret = { days: 0, months: 0, years: 0 };
  const year1 = dt1.getFullYear();
  const year2 = dt2.getFullYear();
  const month1 = dt1.getMonth();
  const month2 = dt2.getMonth();
  const day1 = dt1.getUTCDate();
  const day2 = dt2.getUTCDate();
  ret.years = year2 - year1;
  ret.months = month2 - month1;
  ret.days = day2 - day1;
  if (ret.days < 0) {
    const dtmp1 = new Date(dt1.getFullYear(), dt1.getMonth() + 1, 1, 0, 0, -1);
    const numDays = dtmp1.getUTCDate();
    ret.months -= 1;
    ret.days += numDays;
  }
  if (ret.months < 0) {
    ret.months += 12;
    ret.years -= 1;
  }
  return ret.years ? `${ret.years} Years, ${ret.months} Months` : ret.months ? `${ret.months} Months, ${ret.days} Days` : `${ret.days} Days`;
}

export const getUTCDateFromTimestamp = item => new Date(item.timestamp).getTime();

export const compareDatesWithoutHours = (date1: Date | string, date2: Date | string) => {
  const date1Copy = new Date(date1);
  const date2Copy = new Date(date2);

  return new Date(date1Copy.setHours(0, 0, 0, 0)).getTime() > new Date(date2Copy.setHours(0, 0, 0, 0)).getTime();
};

export const areDatesEqualWithoutHours = (date1: Date, date2: Date) => {
  const date1Copy = new Date(date1);
  const date2Copy = new Date(date2);

  return new Date(date1Copy.setUTCHours(0, 0, 0, 0)).getTime() === new Date(date2Copy.setUTCHours(0, 0, 0, 0)).getTime();
};

export const diffDatesWithoutHours = (date1: Date, date2: Date) => {
  const date1Copy = new Date(date1);
  date1Copy.setUTCHours(0, 0, 0, 0);
  const date2Copy = new Date(date2);
  date2Copy.setUTCHours(0, 0, 0, 0);

  return Math.abs(date1Copy.getTime() - date2Copy.getTime());
};

export const calculateNights = (date1: Date | string, date2: Date | string) => {
  if (!date1 || !date2) return 0;
  const d1 = new Date(date1).getTime();
  const d2 = new Date(date2).getTime();
  const diffTime = Math.abs(d1 - d2);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

export const checkIfSlotsCompleted = ({ selectedSlots, product, isRecurring }: { selectedSlots: Slot[]; product: Product; isRecurring: boolean }) => {
  const multiDayProduct = product?.booking_type === 'MULTI_DAY';
  return (
    selectedSlots.every(slot => {
      if (isRecurring) {
        return slot.days?.length && slot.startDate;
      }

      if (multiDayProduct) {
        return slot.date && slot.dateUntil;
      }

      return slot.date && slot.time;
    }) && selectedSlots.length === product?.sessions
  );
};

export function getPreviousYearDate(date: Date | string | null) {
  if (!date) {
    return null;
  }
  const dateCopy = new Date(date);
  const previousYearDate = new Date(date);
  previousYearDate.setFullYear(dateCopy.getFullYear() - 1);
  return previousYearDate;
}

export function ageToBirthdate(age: number | string) {
  const date = new Date();
  date.setFullYear(date.getFullYear() - +age);
  if (!date?.getTime()) {
    return null;
  }
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return dateInUTC(date);
}

export function dateInUTC(date: Date | string) {
  return new Date(new Date(date).getTime() - offsetUTC(new Date(date)));
}

export const sortByTime = (meals: PetRecord['Meals']) =>
  [...meals].slice().sort((a, b) => {
    const aTime = RRule.fromString(a.time).options;
    const bTime = RRule.fromString(b.time).options;
    const aHour = aTime.byhour[0] || aTime.byhour;
    const aMinute = aTime.byminute[0] || aTime.byminute;
    const bHour = bTime.byhour[0] || bTime.byhour;
    const bMinute = bTime.byminute[0] || bTime.byminute;
    return Number(`${aHour}.${aMinute}`) - Number(`${bHour}.${bMinute}`);
  });

export const toMealReadableText = (time: string) => {
  const repTime = RRule.fromString(time).options;
  const hour = repTime.byhour[0] || repTime.byhour;
  const minute = repTime.byminute[0] || repTime.byminute;
  const h24 = hour < 10 ? '0' + hour : hour;
  const h12 = hour > 12 ? hour - 12 : hour;
  const m = minute < 10 ? '0' + minute : minute;
  const ampm = hour >= 12 ? 'PM' : 'AM';

  return `${h12}:${m} ${ampm}`;
};

export const toSuppReadableText = (time: string) => {
  if (!time) {
    return '';
  }
  const repeatedTime = RRule.fromString(time);
  const text = new RRule({
    freq: repeatedTime.options.freq,
    interval: repeatedTime.options.interval
  }).toText();
  if (text === 'every day' || text === 'every 0 days') {
    return 'Daily';
  }
  return sentenceCase(text);
};

export const findDayName = (day: number) => {
  return FULL_DAYS[day];
};

export const findThreeLetterDayName = (day: number) => {
  return SUN_THREE_LETTERS[day];
};

export const formatDate = (date = new Date()) => {
  const day = date.getDate();
  const dayName = date.getDate() === new Date().getDate() ? 'Today' : findDayName(date.getDay());
  const month = findMonthName(date.getMonth());
  const year = date.getFullYear();
  return `${dayName}, ${day} ${month} ${year}`;
};

export const displayTime = (hours: number, min?: number) => {
  if (hours === 0) {
    return `12:${min !== 0 ? min : '00'} AM`;
  }
  if (hours >= 1 && hours < 12) {
    return `${hours}:${min !== 0 ? min : '00'} AM`;
  }
  if (hours === 12) {
    return `${hours}:${min !== 0 ? min : '00'} PM`;
  }
  if (hours >= 13 && hours <= 23) {
    return `${hours - 12}:${min !== 0 ? min : '00'} PM`;
  }
};

import { DRAWER_IDS } from '../components/DrawerBar/types';
import { BranchSummaryFormValues } from '../views/Store/BranchSummary/types';
import { generateVars } from './utils';

export type DrawerBar = {
  drawerId: DRAWER_IDS;
  recordData?: Record<string, any> | string;
  isPinned?: boolean;
  isExtended?: boolean;
  isFloating?: boolean;
  drawerWidths?: { normal: number; small: number };
  otherData?: Record<string, any>;
  style?: string | null;
};
export type ProductId = string | null | Record<string, any> | undefined;

const initialStates: {
  drawerBars: DrawerBar[];
  productId: ProductId;
  branchSummaryCounts: { counts?: BranchSummaryFormValues['selectedCounts']; isExpanded?: boolean; modes?: { index: number; mode: 'table' | 'chart' }[] } | null;
} = {
  drawerBars: [],
  branchSummaryCounts: null,
  productId: null
};

export const persistedVarsStorageKeys = ['branchSummaryCounts'];

export const vars = generateVars(initialStates, persistedVarsStorageKeys);

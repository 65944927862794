import { PetRecord } from '../../components/Pets/types';
import { ORDER_STATUS, ProductOrder } from '../Orders/types';
import { BranchAppointmentTag } from '../Store/BranchTags/types';
import { Product } from '../Store/types';

export enum BOOKING_TYPE {
  SLOT = 'SLOT',
  MULTI_SLOT = 'MULTI_SLOT',
  MULTI_DAY = 'MULTI_DAY'
}

export enum SLOT_OFFSET_TYPE {
  NO_OFFSET = 'NO_OFFSET',
  START_DATE = 'START_DATE',
  SLOT_TIME_OFFSET = 'SLOT_TIME_OFFSET'
}

export enum BOOKING_TABS_TYPES {
  NEW = 'New',
  CONFIRMED = 'Daily',
  MULTI_DAY = 'Boarding',
  CANCELED = 'Cancelled',
  PAYMENT = 'Payment',
  UPCOMING = 'Upcoming'
}

export enum BOOKING_TABS_VALUES_TYPES {
  NEW = 'requested',
  CONFIRMED = 'confirmed',
  MULTI_DAY = 'multi-day',
  CANCELED = 'canceled',
  PAYMENT = 'payment',
  UPCOMING = 'upcoming'
}

export const BOOKING_TABS_TYPES_LABELS = {
  [BOOKING_TABS_TYPES.NEW]: 'New',
  [BOOKING_TABS_TYPES.CONFIRMED]: 'Daily',
  [BOOKING_TABS_TYPES.MULTI_DAY]: 'Boarding',
  [BOOKING_TABS_TYPES.CANCELED]: 'Cancelled',
  [BOOKING_TABS_TYPES.PAYMENT]: 'Payment',
  [BOOKING_TABS_TYPES.UPCOMING]: 'Upcoming'
};

export enum BOOKING_STATUS_TYPES {
  REQUESTED = 'REQUESTED',
  CONFIRMED = 'CONFIRMED',
  CANCELED = 'CANCELED'
}

export enum BUS_USER_ROLES {
  SUPER_ADMIN = 'SUPER_ADMIN',
  ADMIN = 'ADMIN',
  STAFF = 'STAFF',
  SUPPORT = 'SUPPORT',
  BOT = 'BOT'
}

export type Booking = {
  id: string;
  timestamp: string;
  timestamp_until: string;
  status: BOOKING_STATUS_TYPES;
  BranchAppointmentTags: BranchAppointmentTag[];
  OrderItem: {
    id: string;
    item: Product;
    Order: ProductOrder;
    payment: {
      fail_reason: string;
    };
    quantity: number;
    customizations: any;
  };

  Appointments: Booking[];
  BusUsers: {
    id: string;
    name: string;
    profile_pic: string;
  }[];
  PetRecord: PetRecord;
};

export type BookingOrder = Booking[];

export type CalendarEvent = {
  appointmentsIds: string[];
  itemName: string;
  orderTotal: number;
  petsNames: string;
  BusUsers: {
    id: string;
    name: string;
  }[];
  timestamp: string;
  duration: string;
  branchAppointmentTagsCount: {
    BranchAppointmentTag: BranchAppointmentTag;
    count: number;
  }[];
  totalNumberOfEvents: number;
  timestamp_until: string;
  itemIndex: number;
  isGroup: boolean;
  petsCount: number;
};

export type BlockedCalendarEvent = {
  branchSlotsIds: [string];
  timestamp: string;
  duration: string;
  period: string;
  productsNames: string;
  isCalendarStyle: boolean;
  totalNumberOfEvents: number;
  itemIndex: number;
  description: string;
  count: string;
  Products: Product[];
};

type CalendarEventGroup<T extends Record<string, unknown>> = {
  views: T[];
  date: string;
};

export type CalendarEvents = {
  singleDayAppointmentsViews: CalendarEventGroup<CalendarEvent>[];
  multiDayAppointmentsViews: CalendarEvent[];
  blockedSlotsViews: CalendarEventGroup<BlockedCalendarEvent>[];
};

export const multiDayToolBarButtons = [
  // { name: 'Active', id: 'ACTIVE' },
  // { name: 'Upcoming', id: 'UPCOMING' }
];

export type BOOKING_TAB = {
  name: string;
  value: string;
  status: BOOKING_STATUS_TYPES[];
  booking_type: BOOKING_TYPE[];
  timestamp_from?: Date | string | number | null;
  timestamp_to?: Date | string | number | null;
  order_status: ORDER_STATUS[] | null;
  recordOptions: Record<string, any>;
};
export const BOOKING_RECORD_VIEW_SETTINGS = [
  {
    id: 'recordTags',
    title: 'Tags'
  },
  {
    id: 'recordAddress',
    title: 'Address'
  },
  {
    id: 'recordBookingNotes',
    title: 'Booking Notes'
  },
  {
    id: 'recordPetNotes',
    title: 'Pet Notes'
  },
  {
    id: 'recordFeedingSchedule',
    title: 'Feeding Schedule'
  },
  {
    id: 'recordFeedingRoutine',
    title: 'Feeding Routine'
  },
  {
    id: 'recordTreatmentRoutine',
    title: 'Treatment Routine'
  }
] as const;

export const slotsOffsetTypesOptions = [
  {
    label: 'No notice required',
    value: SLOT_OFFSET_TYPE.NO_OFFSET
  },
  {
    label: 'More than',
    value: SLOT_OFFSET_TYPE.SLOT_TIME_OFFSET
  },
  {
    label: 'Start Date',
    value: SLOT_OFFSET_TYPE.START_DATE
  }
];

import { SelectedBookingsFilter } from '../components/Shared/Filters/config';
import { BOOKING_RECORD_VIEW_SETTINGS } from '../views/Bookings/types';
import { generateVars } from './utils';

export enum GroupBookingsType {
  TAGS = 'Tags',
  BUSUSER_ASSIGNED = 'Staff'
}

const initialStates: {
  selectedBookingsFilters: SelectedBookingsFilter[];
  bookingsHeaderActions: { id: string; action: JSX.Element }[];
  groupBookingsFilters: {
    type: GroupBookingsType | null;
    tagCategoryId: string | null;
    showNotes?: boolean;
    showAddress?: boolean;
    filtersActive?: boolean;
  };
  disableBulkInvoiceCharge: { branchId?: string | null; until?: Date };
  disableBulkInvoiceSendEmail: { branchId?: string | null; until?: Date };
  bookingSelectedCategoryId: string | null;
  bookingVariables: Record<string, any> | null;
  isMultiStaffView: boolean;
  activeViewSettings: {
    record: (typeof BOOKING_RECORD_VIEW_SETTINGS)[number]['id'];
  };
} = {
  selectedBookingsFilters: [],
  bookingsHeaderActions: [],
  groupBookingsFilters: {
    type: null,
    tagCategoryId: null,
    showNotes: false,
    showAddress: false,
    filtersActive: false
  },
  disableBulkInvoiceCharge: {},
  disableBulkInvoiceSendEmail: {},
  bookingSelectedCategoryId: null,
  bookingVariables: null,
  isMultiStaffView: false,
  activeViewSettings: {
    record: [BOOKING_RECORD_VIEW_SETTINGS[0].id]
  }
};

export const persistedVarsStorageKeys = ['disableBulkInvoiceCharge', 'disableBulkInvoiceSendEmail', 'bookingSelectedCategoryId', 'isMultiStaffView', 'selectedTagCategoryId', 'activeViewSettings'];

export const vars = generateVars(initialStates, persistedVarsStorageKeys);
